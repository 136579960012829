import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GenerateInputInformationComponent } from './generate-input-information/generate-input-information.component';
import { GENERATE_INPUT_TYPE, GENERATE_INPUT_TITLE } from './constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generate-input',
  templateUrl: './generate-input.component.html',
  styleUrls: ['./generate-input.component.scss'],
})

/**
 * 入力画面ダイアログ
 */
export class GenerateInputComponent implements OnInit {
  /** 入力情報 */
  @ViewChild(GenerateInputInformationComponent)
  generateInputInformationComponent: GenerateInputInformationComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 入力画面ダイアログ表示フラグ
  inputModal: boolean;

  // ダイアログタイトル
  title: string;
  showLinkPDF: boolean = true;
  linkPDF = 'https://tokyo-ss-dev-file.s3.ap-northeast-1.amazonaws.com/common/%E6%B4%BB%E5%8B%95%E3%81%AE%E5%A0%B4%E5%8B%9F%E9%9B%86%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A0%E5%85%A5%E5%8A%9B%E4%BE%8B.pdf'

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {}

  /**
   * 入力画面表示
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint  REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象ID
   * @param inputQueryParameters 入力項目生成時の絞り込み条件
   * @param endPointWorkInputCheck 業務入力チェックエンドポイント
   */
  public initial(
    inputType: string,
    endPointInput: string,
    endPointGenerateDisplay: string,
    endPoint:string,
    templateId: number,
    pkeyId?: string,
    inputQueryParameters?: Object,
    endPointWorkInputCheck?: string
  ) {
    // 入力画面ダイアログ表示
    this.inputModal = true;
    this.showLinkPDF = false;
    const isM4: boolean = this.router.url.startsWith("/pages/platform/master/m401");
    const isM5: boolean = this.router.url.startsWith("/pages/platform/master/m501");
    // 登録種別の判定
    if (GENERATE_INPUT_TYPE.NEW == inputType) {
      // 登録種別が新規

      if (templateId == 3105) {
        this.showLinkPDF = true
      }
      if (isM4) {
        this.title = GENERATE_INPUT_TITLE.M401_NEW;
      }else if(isM5){
        this.title = GENERATE_INPUT_TITLE.M501_NEW;
      }else{
        // 新規登録画面タイトルを設定
        this.title = GENERATE_INPUT_TITLE.NEW;
      }
    } else if (GENERATE_INPUT_TYPE.EDIT == inputType) {
      // 登録種別が編集

      if (isM4) {
        this.title = GENERATE_INPUT_TITLE.M401_EDIT;
      }else if(isM5){
        this.title = GENERATE_INPUT_TITLE.M501_EDIT;
      }else{
        // 編集画面タイトルを設定
        this.title = GENERATE_INPUT_TITLE.EDIT;
      }
    } else if (GENERATE_INPUT_TYPE.DUPLICATE == inputType) {
      // 登録種別が複製

      
      if (isM4) {
        this.title = GENERATE_INPUT_TITLE.M401_DUPLICATE;
      }else if(isM5){
        this.title = GENERATE_INPUT_TITLE.M501_DUPLICATE;
      }else{
        // 複製画面タイトルを設定
        this.title = GENERATE_INPUT_TITLE.DUPLICATE;
      }
    }

    // 入力情報表示
    this.generateInputInformationComponent.initial(
      inputType,
      endPointInput,
      endPointGenerateDisplay,
      endPoint,
      templateId,
      pkeyId,
      inputQueryParameters,
      endPointWorkInputCheck
    );
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 入力画面ダイアログを閉じる
    this.inputModal = false;

    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }

  openURI() {
    window.open(this.linkPDF, '_blank').focus();
  }
}
