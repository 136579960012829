import { NavigationEnd } from "@angular/router";
import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild,  ElementRef, Renderer2, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "src/app/shared/service/login.service";
import { LoadingState } from "../loading/loading-state";
import { CommonService } from "../../service/common.service";
import { SESSION_KEY } from "../../constant/session-constants";
import { CONSTANT, URL_MYPAGE, NO_LOGIN_SCREENS, ROLES, NO_NEED_LOGIN_SCREENS } from "src/app/shared/constant/constant";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { NotificationDialogComponent } from "src/app/shared/html-parts/notification-dialog/notification-dialog.component";
import { DbOperationService } from "../../service/db-operation.service";
import { COUNT_UNREAD } from "manager/http-constants";
import { IMAGE_URL } from "manager/file-contstants";
import { LinkCheckService } from "../../service/link-check.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})

/**
 * ヘッダー
 */
export class HeaderComponent implements OnInit, OnDestroy {
  // ヘッダー判定フラグ(true:通常モード、false:メッセージモード)
  @Input() headerFlag: boolean;

  @ViewChild(NotificationDialogComponent)
  NotificationDialogComponent: NotificationDialogComponent;

  @ViewChild('avatarContainer') avatarContainer: ElementRef;


  // 文字サイズ選択項目
  textSizeList: Object[] = [
    {
      name: "標準",
      code: "false",
    },
    {
      name: "拡大",
      code: "true",
    },
  ];

  // 文字サイズ
  textSize: string;
  // 課題ID
  agendaId: any;

  id: any;

  cities: any[] = [
    { name: "プロフィール", code: "Profile" },
    { name: "お問い合わせ", code: "Contact" },
    { name: "ログアウト", code: "Logout" },
  ];

  intervalUnread: NodeJS.Timeout;
  role: any[] = [{ name: "Teacher", code: "1" }];
  selectedCity: any;
  menuList: any[] = [];
  indexMenuActive = -1;
  class_id: any;
  lengthMenu: any;
  items: Object[] = [];
  activeItem2: any;
  member_id: any;
  unread_notification: number = 0;
  loginFlag: boolean = false;
  width: number = 0;
  avatarUrl: string;

  constructor(
    private router: Router,
    private commonService: CommonService,
    private loginService: LoginService,
    private loadingState: LoadingState,
    public dbOperationService: DbOperationService,
    private route: ActivatedRoute,
    private linkCheckService: LinkCheckService,
    private renderer: Renderer2

  ) {}
  ngOnDestroy(): void {
    clearInterval(this.intervalUnread);
  }

  ngOnInit() {
    this.width = window.innerWidth
    this.loginService.loginFlag.subscribe((loginFlag: boolean) => {
      this.loginFlag = loginFlag;
      this.updateHeader();
    });
    // this.getCount();  
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.id = e.url;
        for (let index = 0; index < NO_LOGIN_SCREENS.length; index++) {
          const element = NO_LOGIN_SCREENS[index];
          if (e.url.replace('/','').startsWith(element)) {
            return;
          }
        }
        this.updateHeader()
      }
    });
  }

  updateHeader() {
    let user:any = window.sessionStorage.getItem(SESSION_KEY.loginUserInformation);
    this.setAvatar(user);
    
    if (user) {
      user = JSON.parse(user);
      this.loadingState.loadSleepEnd(0.3);
      this.class_id = user.class_id;
      this.member_id = user.member_id;
      this.updateDataDisplayMenu();
      this.updateRightMenu();
      this.getCount();
    } else if (this.loginFlag) {
      this.loginService.getLoginUser().subscribe((response) => {
        this.loadingState.loadSleepEnd(0.3);

        if (this.commonService.checkNoneResponse(response)) {
          this.loginService.logout(MESSAGE_CODE.E90000);
          return;
        } else {
          window.sessionStorage.setItem(SESSION_KEY.loginUserInformation, JSON.stringify(response.body));
          this.class_id = response.body.class_id;
          this.member_id = response.body.member_id;
          this.updateDataDisplayMenu();
          this.updateRightMenu();
          this.getCount();
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.width = window.innerWidth;
    this.updateDataDisplayMenu();
    this.updateRightMenu();
  }

  // private initUpdateBell() {
  //   this.intervalUnread = setInterval(async () => {
  //     this.getCount();
  //   }, 5000);
  // }

  public updateRightMenu(): void {
    switch (this.class_id) {
      case ROLES.TEACHER:
        if(this.width > 930){
          this.items = [
            {
              label: "ログアウト",
              icon: "pi pi-sign-out",
              command: (event) => {
                this.loginService.logout();
              },
            }
          ];
        }
        else {
          this.items = [
            {
              label: "会員トップ",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.TEACHER.TOP,
            },
            {
              label: "活動の場登録",
              icon: "pi pi-pencil",
              url: "/pages/platform/master/m401",
            },            
            {
              label: "応募・依頼",
              icon: "pi pi-pencil",
              url: "/pages/platform/c502",
            },
            {
              label: "プロフィール",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.TEACHER.PROFILE,
            },
            {
              label: "サポーターリスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c231",
            },
            {
              label: "活動の場リスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c241",
            },
            { separator: true },
            {
              label: "ログアウト",
              icon: "pi pi-sign-out",
              command: (event) => {
                this.loginService.logout();
              },
            },
          ];
        }
        break;

      case ROLES.PROVIDER:
        if(this.width > 930) {
          this.items = [
            {
              label: "ログアウト",
              icon: "pi pi-sign-out",
              command: (event) => {
                this.loginService.logout();
              },
            },
          ];
        }
        else {
          this.items = [
            {
              label: "会員トップ",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.PROVIDER.TOP,
            },
            {
              label: "オンライン研修",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.PROVIDER.LEARNING,
            },
            {
              label: "応募・依頼",
              icon: "pi pi-pencil",
              url: "/pages/platform/c502",
            },
            {
              label: "プロフィール",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.PROVIDER.PROFILE,
            },
            {
              label: "活動の場リスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c241",
            },
            // {
            //   label: "サポーターリスト",
            //   icon: "pi pi-pencil",
            //   url: "/pages/platform/c231",
            // },
            { separator: true },
            {
              label: "ログアウト",
              icon: "pi pi-sign-out",
              command: (event) => {
                this.loginService.logout();
              },
            },

          ];
        }
        break;

      case ROLES.ADMIN:
        if(this.width > 930) {
          this.items = [
            {
              label: "ログアウト",
              icon: "pi pi-sign-out",
              command: (event) => {
                this.loginService.logout();
              },
            },
            { separator: true },
            {
              label: "お知らせマスタ",
              icon: "pi pi-user-edit",
              url: "/pages/platform/master/m501",
            },
            {
              label: "サポーターリスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c231",
            },
            {
              label: "活動の場リスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c241",
            },
          ];
        }
        else {
          this.items = [
            {
              label: "会員トップ",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.ADMIN.TOP,
            },
            {
              label: "オンライン研修",
              icon: "pi pi-pencil",
              url: URL_MYPAGE.ADMIN.LEARNING,
            },
            {
              label: "活動の場登録",
              icon: "pi pi-pencil",
              url: "/pages/platform/master/m401",
            },
            {
              label: "応募・依頼",
              icon: "pi pi-pencil",
              url: "/pages/platform/c502",
            },
            {
              label: "サポーター",
              icon: "pi pi-pencil",
              url: "/pages/platform/master/m301",
            },
            {
              label: "お知らせマスタ",
              icon: "pi pi-user-edit",
              url: "/pages/platform/master/m501",
            },
            {
              label: "サポーターリスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c231",
            },
            {
              label: "活動の場リスト",
              icon: "pi pi-pencil",
              url: "/pages/platform/c241",
            },
            { separator: true },
            {
              label: "ログアウト",
              icon: "pi pi-sign-out",
              command: (event) => {
                this.loginService.logout();
              },
            }
          ];
        }        
        break;
    }
  }

  public updateDataDisplayMenu(): void {
    switch (this.class_id) {
      case ROLES.TEACHER: {
        if(this.width > 930) {
          this.menuList = [
            {
              title1: "会員トップ",
              url: URL_MYPAGE.TEACHER.TOP,
              index: 0,
              changePage: true
            },
            {
              title1: "活動の場登録",
              link: "/pages/platform/master/m401",
              index: 1,
            },
            {
              title1: "応募・依頼",
              link: "/pages/platform/c502",
              index: 2,
            },
            {
              title1: "プロフィール",
              url: URL_MYPAGE.TEACHER.PROFILE,
              index: 3,
              changePage: true
            },
            {
              title1: "サポーターリスト",
              link: "/pages/platform/c231",
              index: 4,
            },
            {
              title1: "活動の場リスト",
              link: "/pages/platform/c241",
              index: 5,
            },
          ];
        }
        else {
          this.menuList = []
        }        
        break;
      }

      case ROLES.PROVIDER: {
        if(this.width > 930) {
          this.menuList = [
            {
              title1: "会員トップ",
              url: URL_MYPAGE.PROVIDER.TOP,
              index: 0,
              changePage: true
            },
            {
              title1: "オンライン研修",
              url: URL_MYPAGE.PROVIDER.LEARNING,
              index: 1,
              changePage: true
            },
            {
              title1: "応募・依頼",
              link: "/pages/platform/c502",
              index: 2,
            },
            {
              title1: "プロフィール",
              url: URL_MYPAGE.PROVIDER.PROFILE,
              index: 3,
              changePage: true
            },
            {
              title1: "活動の場リスト",
              link: "/pages/platform/c241",
              index: 4,
            },
            // {
            //   title1: "サポーターリスト",
            //   link: "/pages/platform/c231",
            //   index: 5,
            // },
          ];
        }
        else {
          this.menuList = []
        }        
        break;
      }
      case ROLES.ADMIN: {
        if(this.width > 930) {
          this.menuList = [
            {
              title1: "会員トップ",
              url: URL_MYPAGE.ADMIN.TOP,
              index: 0,
              changePage: true
            },
            {
              title1: "オンライン研修",
              url: URL_MYPAGE.ADMIN.LEARNING,
              index: 1,
              changePage: true
            },
            {
              title1: "活動の場登録",
              link: "/pages/platform/master/m401",
              index: 2,
            },
            {
              title1: "応募・依頼",
              link: "/pages/platform/c502",
              index: 3,
            },
            {
              title1: "サポーター",
              link: "/pages/platform/master/m301",
              index: 4,
            },
          ];
        }
        else {
          this.menuList = []
        }        
        break;
      }
    }
  }

  public getLoginClassId(): string | number {
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    if (!loginUser) {
      return CONSTANT.EMPTY_STRING;
    }

    return loginUser.class_id;
  }

  public isActive(menuItem?: any): void | boolean {
    if (menuItem && this.router.url === menuItem?.link) {
      if (this.indexMenuActive >= 0) {
        document
          .querySelectorAll(".p-tabmenuitem")
          [this.indexMenuActive]?.classList.remove("white-background");
          document
          .querySelectorAll(".text-white-color")
          [this.indexMenuActive]?.classList.remove("text-red-color");
      

        this.indexMenuActive = -1;
      }

      document
        .querySelectorAll(".p-tabmenuitem")
        [menuItem.index].classList.add("white-background");
        document
        .querySelectorAll(".text-white-color")
        [menuItem.index].classList.add("text-red-color");
    } else {
      if (this.class_id === ROLES.TEACHER) {
        if (
          this.router.url.startsWith("/pages/platform/master/m401")
        ) {
          this.indexMenuActive = 1;
        } else if (
          this.router.url.startsWith("/pages/platform/c502")
        ) {
          this.indexMenuActive = 2;
        } else if (
          this.router.url.startsWith("/pages/platform/c231")
        ) {
          this.indexMenuActive = 4;
        } else if (
          this.router.url.startsWith("/pages/platform/c241")
        ) {
          this.indexMenuActive = 5;
        } else {
          this.indexMenuActive = -1;
        }
      } else if (this.class_id === ROLES.ADMIN) {
        if (
          this.router.url.startsWith("/pages/platform/c502")
        ) {
          this.indexMenuActive = 3;
        } else if (
          this.router.url.startsWith("/pages/platform/master/m401")
        ) {
          this.indexMenuActive = 2;
        } else if (
          this.router.url.startsWith("/pages/platform/master/m301")
        ) {
          this.indexMenuActive = 4;
        } else {
          this.indexMenuActive = -1;
        }
      } else if (this.class_id === ROLES.PROVIDER) {
        if (
          this.router.url.startsWith("/pages/platform/c231")
        ) {
          this.indexMenuActive = 5;
        } else if (
          this.router.url.startsWith("/pages/platform/c241")
        ) {
          this.indexMenuActive = 4;
        } else if (
          this.router.url.startsWith("/pages/platform/c502")
        ) {
          this.indexMenuActive = 2;
        } else {
          this.indexMenuActive = -1;
        }
      }

      document
        .querySelectorAll(".p-tabmenuitem")
        [this.indexMenuActive]?.classList.add("white-background");
        document
        .querySelectorAll(".text-white-color")
        [this.indexMenuActive]?.classList.add("text-red-color");
    }
  }

  public checkShowMenu(): boolean {
    if (
      (this.id &&
      (this.id.startsWith("/pages/platform/a201") ||
        this.id.startsWith("/pages/platform/a203") ||
        this.id.startsWith("/pages/platform/a210") ||
        this.id.startsWith("/pages/platform/a211") ||
        this.id.startsWith("/pages/platform/a212") ||
        this.id.startsWith("/pages/platform/a213") ||
        this.id.startsWith("/pages/platform/a203") ||
        this.id.startsWith("/pages/platform/a204") ||
        this.id.startsWith("/pages/platform/landing") ||
        this.id.startsWith("/pages/platform/contact") ||
        ((this.id.startsWith("/pages/platform/c241") ||
        this.id.startsWith("/pages/platform/c341")) && !this.loginFlag))) || !this.loginFlag
    ) {
      return false;
    }
    return true;
  }

  public checkShowIconButton(): boolean {
    if (
      this.id &&
      (this.id.startsWith("/pages/platform/a201") ||
        this.id.startsWith("/pages/platform/a203") ||
        this.id.startsWith("/pages/platform/a210") ||
        this.id.startsWith("/pages/platform/a211") ||
        this.id.startsWith("/pages/platform/a212") ||
        this.id.startsWith("/pages/platform/a213") ||
        this.id.startsWith("/pages/platform/a203") ||
        this.id.startsWith("/pages/platform/a204") ||
        this.id.startsWith("/pages/platform/landing") ||
        this.id.startsWith("/pages/platform/contact"))
    ) {
      return false;
    }
    return true;
  }

  public checkLanding(): boolean {
    if (
      (this.id &&
      (this.id.startsWith("/pages/platform/landing") ||
        this.id.startsWith("/pages/platform/contact") ||
        ((this.id.startsWith("/pages/platform/c241") ||
        this.id.startsWith("/pages/platform/c341")) && !this.loginFlag)
      )) || !this.loginFlag
    ) {
      return true;
    }
    return false;
  }
  public headerActive(): boolean {
    if (this.id && this.id.startsWith("/pages/platform/landing")) {
      return false;
    }
    return true;
  }
  openNotificationDialog(event: Event) {
    this.NotificationDialogComponent.openDialog(event);
  }
  login() {
    this.loginService.logout();
  }
  goToMyPage() {
    window.location.href = URL_MYPAGE.OTHERS.LOGIN
  }
  getCount() {
    if(!window.sessionStorage.getItem(SESSION_KEY.accessToken)){
      return;
    }
    this.dbOperationService
      .getNoTemplateData(COUNT_UNREAD, {}, false)
      .subscribe((response) => {
        if (!this.commonService.checkNoneResponse(response)) {
          this.unread_notification = response.body.count;
        } else {
          this.unread_notification = 0;
        }
      });
  }
  changeUrl() {
    window.location.href = 'https://stg-mypage.sumasapo.metro.tokyo.lg.jp/lp/'
  }

  onDisplayProfile() {
    window.location.href = 'https://stg-mypage.sumasapo.metro.tokyo.lg.jp/profile';
  }

  setAvatar(userInfo: any) {
    if (this.avatarContainer) {
      if (userInfo) {
        userInfo = JSON.parse(userInfo);
      }
      this.linkCheckService.validImage(IMAGE_URL.replace("{%s}", userInfo?.integration_id)).subscribe(imgUrl => {
        this.renderer.setStyle(this.avatarContainer.nativeElement, 'backgroundImage', `url(${imgUrl})`);
      });
    }
  }
}
