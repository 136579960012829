import { SocketIoConfig } from "ngx-socket-io";

/**
 * プロジェクトタイトル
 */
export const TITLE = {
  // タイトルバー
  TITLE_BAR: "stg TOKYOスマホサポーター",
  // タイトルフッダー
  TITLE_FOOTER: "",
  // 開設日
  OPENING_DATE:
    "",
};

/**
 * testドメイン
 */
export const TEST_DOMAIN = "d17w143sy54xl7.cloudfront.net";

/**
 * 本番ドメイン
 */
export const PROD_DOMAIN = "match.sumasapo.metro.tokyo.lg.jp";

/**
 * domain stagging
 */
export const STAGING_DOMAIN = "d2t46vin868aef.cloudfront.net";

/**
 * domain stagging
 */
export const STG_DOMAIN = "stg-match.sumasapo.metro.tokyo.lg.jp";

/**
 * 非ログイン用APIキー
 */
// export const API_KEY = "e33f5421bc3645be8a0dece6689fac2f";

/**
 * auth0設定情報
 */
export const auth0_dev = {
  production: false,
  AUTH0_DOMAIN: "stgid.sumasapo.metro.tokyo.lg.jp", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "kz97s8sgy4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(test)
 */
export const auth0_test = {
  production: false,
  AUTH0_DOMAIN: "stgid.sumasapo.metro.tokyo.lg.jp", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "kz97s8sgy4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(prod)
 */
export const auth0_prod = {
  production: false,
  AUTH0_DOMAIN: "id.sumasapo.metro.tokyo.lg.jp", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "y7z6ak7zu5", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(staging)
 */
 export const auth0_stagging = {
  production: false,
  AUTH0_DOMAIN: "stgid.sumasapo.metro.tokyo.lg.jp", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "kz97s8sgy4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};


/**
 * auth0設定情報(stg)
 */
export const auth0_stg = {
  production: false,
  AUTH0_DOMAIN: "stgid.sumasapo.metro.tokyo.lg.jp", // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: "kz97s8sgy4", // ApplicationsのClient ID(auth0情報)
  AUDIENCE: "https://aws-jwt-authorizer", // APIsのAPI Audience(auth0情報)
  API_URL: "https://*", // トークン付与対象APIエンドポイント
};

/**
 * 国際化（i18n）設定
 */
export const environment_i18n = {
  production: false,
  defaultLang: "ja",
};

/**
 * セッションキー
 */
export const SESSION_KEY = {
  // ログインユーザ情報
  loginUserInformation: "loginUserInformation",

  // TODO ログアウト時のメッセージコード格納に使用　※auth0が間に入り、いいパラメータ受け渡し方法が見つかるまで使用
  // ログインアウト時メッセージコード
  loginOutMessageCode: "loginOutMessageCode",
};

export const CLOSE_LOGIC1_DEV_DOMAIN =
  "https://ss9jox19a5.execute-api.ap-northeast-1.amazonaws.com/dev/";
export const CLOSE_LOGIC2_DEV_DOMAIN =
  "https://2jpzavq851.execute-api.ap-northeast-1.amazonaws.com/dev/";
export const OPEN_LOGIC1_DEV_DOMAIN =
  "https://fdu9hn3uig.execute-api.ap-northeast-1.amazonaws.com/dev/";
export const OPEN_LOGIC2_DEV_DOMAIN =
  "https://q5nmvdwxkj.execute-api.ap-northeast-1.amazonaws.com/dev/";

export const OPEN_LOGIC1_TEST_DOMAIN =
  "https://n1xozv1mki.execute-api.ap-northeast-1.amazonaws.com/test/";
export const CLOSE_LOGIC1_TEST_DOMAIN =
  "https://5rradn1k5i.execute-api.ap-northeast-1.amazonaws.com/test/";
export const CLOSE_LOGIC2_TEST_DOMAIN =
  "https://16kj6nxrmh.execute-api.ap-northeast-1.amazonaws.com/test/";

export const OPEN_LOGIC1_STAGING_DOMAIN =
  "https://xgcs9mxnmd.execute-api.ap-northeast-1.amazonaws.com/staging/";
export const CLOSE_LOGIC1_STAGING_DOMAIN =
  "https://8vv3b6xul9.execute-api.ap-northeast-1.amazonaws.com/staging/";
export const CLOSE_LOGIC2_STAGING_DOMAIN =
  "https://xbru1s3hfd.execute-api.ap-northeast-1.amazonaws.com/staging/";

export const OPEN_LOGIC1_STG_DOMAIN =
    "https://yp1r2m3376.execute-api.ap-northeast-1.amazonaws.com/stg/";
export const CLOSE_LOGIC1_STG_DOMAIN =
    "https://dgtpj2ivmk.execute-api.ap-northeast-1.amazonaws.com/stg/";
export const CLOSE_LOGIC2_STG_DOMAIN =
    "https://j0ttlftejc.execute-api.ap-northeast-1.amazonaws.com/stg/";

export const OPEN_LOGIC1_PROD_DOMAIN =
    "https://tj8w8n6tsf.execute-api.ap-northeast-1.amazonaws.com/prod/";
export const CLOSE_LOGIC1_PROD_DOMAIN =
    "https://ljwlqc8p1m.execute-api.ap-northeast-1.amazonaws.com/prod/";
export const CLOSE_LOGIC2_PROD_DOMAIN =
    "https://fvdatrscmc.execute-api.ap-northeast-1.amazonaws.com/prod/";

export const socketIoConfig: SocketIoConfig = {
  url: "http://localhost:8080",
  // url: "https://54.95.104.20:8080",
  options: {},
};
